<template>
  <!-- TOP Nav Bar -->
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom" :class="horizontal ? 'd-flex align-items-center justify-content-between' : ''">
      <div class="iq-menu-bt d-flex align-items-center" v-if="horizontal">
          <div class="wrapper-menu" @click="miniSidebar"  v-if="horizontal">
            <div class="main-circle"><i class="ri-menu-line"></i></div>
            <div class="hover-circle"><i class="ri-close-fill"></i></div>
          </div>
          <div class="iq-navbar-logo d-flex justify-content-between">
            <a href="#" class="header-logo">
                <img :src="logo" class="img-fluid rounded-normal" alt="">
                <div class="pt-2 pl-2 logo-title">
                  <!-- <span class="text-danger text-uppercase">C9<span class="text-white ml-1">eye</span></span> -->
                </div>
            </a>
          </div>
      </div>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <CollapseMenu :items="items" :open="true" :horizontal="horizontal" :sidebarGroupTitle="sidebarGroupTitle" class="collapse-menu"/>
          <HoverMenu :items="items" :sidebarGroupTitle="sidebarGroupTitle" class="hover-menu" />
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="navbar-breadcrumb"  v-if="!horizontal">
            <h4 v-if="is_superuser == 'true'" class="mb-0 text-dark">SuperAdmin</h4>
            <h4 v-else class="mb-0 text-dark">Admin</h4>
            <!-- <p class="mb-0"><span class="text-danger">Hi there,</span> Great to see you again</p> -->
        </div>
        <b-navbar-toggle target="nav-collapse" class="mr-2">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div class="iq-menu-bt d-flex align-items-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
            <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
          </div>
          <!-- <div class="iq-navbar-logo d-flex justify-content-between" v-if="!horizontal">
            <router-link :to="homeURL" class="header-logo">
              <img :src="logo" class="img-fluid rounded-normal" alt="logo">
              <div class="pt-2 pl-2 logo-title font-size-21 font-weight-500">
                <span class="text-danger text-uppercase">C9<span class="text-primary ml-1">eye</span></span>
              </div>
            </router-link>
          </div> -->
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight"/>
        </b-collapse>
        <slot name="right"/>
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>

<script>
import { APPNAME } from '../../../config/pluginInit'
import { mapGetters } from 'vuex'
import HoverMenu from '../menus/HoverMenu'
import CollapseMenu from '../menus/CollapseMenu'
import axios from "axios";
import constant from "@/config/constant";
import CryptoJS from "crypto-js";
export default {
  name: 'DefaultNavBar',
  props: {
    // homeURL: { type: Object, default: () => ({ name: 'dashboard.webmonitoring' }) },
    // logo: { type: String, default: require('../../../assets/images/logo.jpg') },
    horizontal: { type: Boolean, default: false },
    items: { type: Array },
    sidebarGroupTitle: { type: Boolean, default: true }
  },
  mounted () {
    if (!this.horizontal) {
      document.addEventListener('click', this.closeSearch, true)
    }
    this.fetchUserDetails();
  },
  components: {
    HoverMenu,
    CollapseMenu
  },
  computed: {
    ...mapGetters({
      bookmark: 'Setting/bookmarkState'
    })
  },
  data () {
    return {
      appName: APPNAME,
      showSearch: false,
      showMenu: false,
      is_superuser: null
    }
  },
  methods: {
    decryptToken(encryptedToken, key) {
      const bytes = CryptoJS.AES.decrypt(encryptedToken, key);
      const decryptedToken = bytes.toString(CryptoJS.enc.Utf8);
      return decryptedToken;
    },
    async fetchUserDetails() {
      try {
       
        const token = localStorage.getItem("token");
        
        const key = "enc"; // Fetch encrypted token
        const decryptedToken = this.decryptToken(token, key);
        const response = await axios.get(
          constant.API_BASE_URL + "me/",
          {
            headers: { Authorization: "Token " + decryptedToken },
          }
        );
        this.is_superuser = response.data.data[0].is_superuser;
        localStorage.setItem("superadmin", this.is_superuser);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          if (error.response.data?.detail == "Invalid token.") {
            // Clear the token from local storage
            localStorage.removeItem("token");
            console.log("Token cleared due to invalid credentials.");
            this.$router.push({ name: "sign-in" });
          }

        }
      }
    },
    miniSidebar () {
      this.$emit('toggle')
    },
    openSearch () {
      this.showSearch = true
      this.showMenu = 'iq-show'
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null) {
        document.getElementById('searchbox-datalink').classList.add('show-data')
      }
    },
    closeSearch (event) {
      let classList = event.target.classList
      if (!classList.contains('searchbox') && !classList.contains('search-input')) {
        this.removeClass()
      }
    },
    removeClass () {
      this.showSearch = false
      this.showMenu = ''
      this.globalSearch = ''
      if (document.getElementById('searchbox-datalink') !== null && document.getElementById('searchbox-datalink') !== undefined) {
        document.getElementById('searchbox-datalink').classList.remove('show-data')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .collapse-menu{
    @media (min-width:1300px) {
      display: none;
    }
  }
  .iq-sidebar-menu .iq-menu.hover-menu{
    display: flex;
    @media (max-width:1299px){
      display: none !important;
    }
  }
</style>
